/** @jsx jsx */
import React from "react"
import useSWR from "swr"
import { jsx } from "theme-ui"

export default function() {
  const fetcher = async (...args) => {
    const res = await fetch(...args)
    return res.json()
  }
  const { data, error } = useSWR("/api/weather-emoji", fetcher)

  if (error) return <>😱</>
  if (!data) return <>🙄</>
  return (
    <span
      title={`It's ${data.summary.toLowerCase()} and ${
        data.temperature
      }°F right now in Seattle`}
    >
      {data.emoji}
    </span>
  )
}
